import StickyHeader from "components/common/StickyHeader";
import OpgaveDropdown from "./OpgaveDropdown";
import OpgaveStatusToggle from "./OpgaveStatusToggle";
import { OpgaveKommentarAdgang } from "interfaces/Opgaveliste/enums";
import Noter from "./Noter";
import NextPreviousOpgave from "./NextPreviousOpgave";
import { AuthState } from "context/AuthProvider";

interface OpgaveToolbarProps {
  currentOpgave: Opgave | undefined;
  currentOpgaveListe: OpgaveListe | undefined;
  opgaverLoading: boolean;
  opgaver: Opgave[] | undefined;
  opgavelister: OpgaveListe[] | undefined;
  setOpgaver: React.Dispatch<React.SetStateAction<Opgave[] | undefined>>;
  erSkatteforvaltningen: boolean;
  opgaveOrder: OpgaveOrder | undefined;
  listeLoading: boolean;
  authState: AuthState;
}
export default function OpgaveToolbar(props: OpgaveToolbarProps) {
  const {
    currentOpgave,
    currentOpgaveListe,
    opgaver,
    opgaverLoading,
    opgavelister,
    setOpgaver,
    erSkatteforvaltningen,
    authState,
    listeLoading,
    opgaveOrder,
  } = props;

  return (
    <StickyHeader>
      <div
        className={`container-fluid bg-white shadow-sm rounded d-flex align-items-center gap-3 justify-content-between ${
          currentOpgave ? "border border-primary" : "border border-tertiary"
        }`}
        style={{
          height: 70,
          paddingTop: "0.8rem",
          paddingBottom: "0.8rem",
        }}
      >
        {!opgaver && opgaverLoading && <span>Henter...</span>}
        {opgaver && !opgaverLoading && opgaver.length === 0 && (
          <p className="mb-0 ms-2 text-primary text-nowrap">
            <span className="fw-bold">0</span> fejl på ejendommen
          </p>
        )}
        {opgavelister && !opgaverLoading && (
          <OpgaveDropdown
            opgavelister={opgavelister}
            opgaver={opgaver}
            currentOpgave={currentOpgave}
            opgaverLoading={opgaverLoading}
          />
        )}
        {opgavelister && !opgaverLoading && (
          <OpgaveStatusToggle
            opgaver={opgaver}
            currentOpgave={currentOpgave}
            setOpgaver={setOpgaver}
            brugerStatusUI={currentOpgaveListe?.brugerstatus}
            erSkatteforvaltningen={erSkatteforvaltningen}
          />
        )}
        {currentOpgave && (
          <>
            {opgaver &&
            currentOpgave?.kommentarAdgang !==
              OpgaveKommentarAdgang.ingenAdgang ? (
              <Noter
                opgaver={opgaver}
                setOpgaver={setOpgaver}
                currentOpgave={currentOpgave}
                title="Noter"
                icon
              />
            ) : null}{" "}
            <NextPreviousOpgave
              {...{
                authState,
                opgaver,
                opgaveOrder,
                liste: currentOpgaveListe,
                erSkatteforvaltningen,
                listeLoading,
              }}
            />
          </>
        )}
      </div>
    </StickyHeader>
  );
}
