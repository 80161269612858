import { useContext } from "react";
import { OpgaveBrugerStatus } from "interfaces/Opgaveliste/enums";
import opgaveApi from "api/opgave";
import { AuthContext } from "context/AuthProvider";
import StatusBadge from "routes/Fejllister/StatusBadge";

import { useMutation } from "react-query";

export default function OpgaveStatusToggle(props: OpgaveStatusToggleProps) {
  const {
    erSkatteforvaltningen,
    brugerStatusUI,
    opgaver,
    setOpgaver,
    currentOpgave,
  } = props;

  const authState = useContext(AuthContext);

  const { mutate, isLoading } = useMutation({
    mutationFn: async function (options: {
      id: string;
      brugerStatus: OpgaveBrugerStatus;
    }) {
      const { brugerStatus, id } = options;
      const ol = await opgaveApi.putOpgaveBrugerStatus(
        id,
        brugerStatus,
        authState
      );
      if (ol && opgaver && currentOpgave) {
        setOpgaver(
          opgaver.map((o) => {
            if (o.id === ol.id) {
              return ol;
            }
            return o;
          })
        );
      }
    },
  });

  const hasKanIkkeLoesesButton =
    erSkatteforvaltningen &&
    brugerStatusUI !== undefined &&
    brugerStatusUI.findIndex((b) => b.status === 5 && b.visesUi) > -1;

  return (
    <>
      {currentOpgave && opgaver && opgaver.length > 0 && (
        <>
          <div
            className="rounded-pill border h-100 p-1 d-flex gap-1 align-items-center"
            style={isLoading ? { opacity: 0.8 } : {}}
          >
            <div
              className={`c-avatar c-avatar--sm bg-primary text-white ${
                currentOpgave?.tilhoererBruger?.initialer.length
                  ? "d-flex"
                  : "d-none"
              }`}
              aria-label={`Bruger: ${currentOpgave?.tilhoererBruger?.navn}`}
            >
              {currentOpgave?.tilhoererBruger?.initialer}
            </div>
            <button
              className="has-tooltip tooltip-center tooltip-bottom"
              disabled={!currentOpgave}
              aria-label={
                currentOpgave?.brugerStatus !== 0
                  ? "Sæt status til 'Åben"
                  : "Status er sat til 'Åben'"
              }
              onClick={() => {
                mutate({
                  id: currentOpgave?.id ?? "",
                  brugerStatus: OpgaveBrugerStatus.ikkeTildelt,
                });
              }}
            >
              <StatusBadge
                type="open"
                muted={currentOpgave?.brugerStatus !== 0}
              >
                Åben
              </StatusBadge>
            </button>
            <button
              className="has-tooltip tooltip-center tooltip-bottom"
              aria-label={
                currentOpgave?.brugerStatus !== 1
                  ? "Sæt status til 'I gang"
                  : "Status er sat til 'I gang'"
              }
              disabled={!currentOpgave}
              onClick={() => {
                mutate({
                  id: currentOpgave?.id ?? "",
                  brugerStatus:
                    currentOpgave?.brugerStatus === OpgaveBrugerStatus.iGang
                      ? OpgaveBrugerStatus.ikkeTildelt
                      : OpgaveBrugerStatus.iGang,
                });
              }}
            >
              <StatusBadge
                type="onit"
                muted={currentOpgave?.brugerStatus !== 1}
              >
                I gang
              </StatusBadge>
            </button>
            <button
              disabled={!currentOpgave}
              className="has-tooltip tooltip-center tooltip-bottom"
              aria-label={
                currentOpgave?.brugerStatus !== 2
                  ? "Sæt status til 'Færdig"
                  : "Status er sat til 'Færdig'"
              }
              onClick={() => {
                mutate({
                  id: currentOpgave?.id ?? "",
                  brugerStatus: OpgaveBrugerStatus.opgaveLoest,
                });
              }}
            >
              <StatusBadge
                type="done"
                muted={currentOpgave?.brugerStatus !== 2}
              >
                Færdig
              </StatusBadge>
            </button>
            <button
              className={`has-tooltip tooltip-center tooltip-bottom ${
                hasKanIkkeLoesesButton ? "d-flex" : "d-none"
              }`}
              disabled={!currentOpgave}
              aria-label={
                currentOpgave?.brugerStatus !== 5
                  ? "Sæt status til 'Fejl"
                  : "Status er sat til 'Fejl'"
              }
              onClick={() => {
                mutate({
                  id: currentOpgave?.id ?? "",
                  brugerStatus: OpgaveBrugerStatus.kanIkkeLoeses,
                });
              }}
            >
              <StatusBadge
                type="pause"
                muted={currentOpgave?.brugerStatus !== 5}
              >
                Fejl
              </StatusBadge>
            </button>
          </div>
          {/* Old status dropdown - TODO: reuse element for mobile view */}
          {/* <StatusDialog
          width={matches ? "260px" : "210px"}
          arrowClass="text-muted"
          dropdownTriggerStyle={` ${
            matches
              ? "rounded-3 text-muted"
              : "h-100 c-statusdropdown-item gap-3 d-flex align-items-center justify-content-start"
          }`}
          activeButtonStyle={matches ? true : false}
          noArrow={matches ? true : false}
          element={
            <>
              {!matches && (
                <div className="d-flex gap-2 align-items-center h-100 pe-2">
                  <p className="mb-0 p-1 text-muted fw-medium fs-5">
                    Status:
                  </p>
                  <div className="d-flex gap-2">
                    <motion.div
                      className="c-avatar c-avatar--sm bg-primary text-white"
                      variants={variants}
                      animate={
                        currentOpgave?.tilhoererBruger?.initialer
                          .length
                          ? "show"
                          : "hidden"
                      }
                      transition={{ damping: 300 }}
                    >
                      {currentOpgave?.tilhoererBruger?.initialer}
                    </motion.div>
                    {currentOpgave?.brugerStatus === 0 && (
                      <StatusBadge type="open">Åben</StatusBadge>
                    )}
                    {currentOpgave?.brugerStatus === 2 && (
                      <StatusBadge type="done">Færdig</StatusBadge>
                    )}
                    {currentOpgave?.brugerStatus === 1 && (
                      <StatusBadge type="onit">I gang</StatusBadge>
                    )}
                    {currentOpgave?.brugerStatus === 5 && (
                      <StatusBadge type="pause">Fejl</StatusBadge>
                    )}
                  </div>
                </div>
              )}
              {matches && (
                <span
                  className="px-2 py-2 d-flex align-items-center justify-content-center rounded hover-light hover-primary-svg"
                  style={{ width: "46px", height: "44px" }}
                >
                  <span className="hover-svg">
                    <Circle width={23} />
                  </span>
                </span>
              )}
            </>
          }
          menu={[
            <button
              className="list-group-item list-group-item-action d-flex w-100 justify-content-start gap-3 ps-4 pe-5 py-2"
              disabled={buttonState.state !== "IDLE"}
              onClick={() => {
                if (currentOpgave)
                  putOpgaveBrugerStatus(
                    currentOpgave.id,
                    currentOpgave.brugerStatus ===
                      OpgaveBrugerStatus.iGang
                      ? OpgaveBrugerStatus.ikkeTildelt
                      : OpgaveBrugerStatus.iGang
                  );
              }}
            >
              <div style={{ minWidth: "20px" }}>
                {currentOpgave?.brugerStatus ===
                  OpgaveBrugerStatus.iGang && <CheckLg width={20} />}
              </div>
              <div style={{ minWidth: "30px" }}>
                <div
                  className="c-avatar c-avatar--sm bg-primary text-white"
                  style={{
                    visibility:
                      currentOpgave?.tilhoererBruger?.initialer
                        .length &&
                      currentOpgave.brugerStatus ===
                        OpgaveBrugerStatus.iGang
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {currentOpgave?.tilhoererBruger?.initialer}
                </div>
              </div>
              <StatusBadge type="onit">I gang</StatusBadge>
            </button>,
            <button
              disabled={buttonState.state !== "IDLE"}
              className="list-group-item list-group-item-action d-flex w-100 justify-content-start gap-3 ps-4 pe-5 py-2"
              onClick={() => {
                if (currentOpgave)
                  putOpgaveBrugerStatus(
                    currentOpgave.id,
                    (currentOpgave.brugerStatus =
                      OpgaveBrugerStatus.opgaveLoest)
                  );
              }}
            >
              <div style={{ minWidth: "20px" }}>
                {currentOpgave?.brugerStatus ===
                  OpgaveBrugerStatus.opgaveLoest && (
                  <CheckLg width={20} />
                )}
              </div>
              <div style={{ minWidth: "30px" }}>
                <div
                  className="c-avatar c-avatar--sm bg-primary text-white"
                  style={{
                    visibility:
                      currentOpgave?.tilhoererBruger?.initialer
                        .length &&
                      currentOpgave.brugerStatus ===
                        OpgaveBrugerStatus.opgaveLoest
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {currentOpgave?.tilhoererBruger?.initialer}
                </div>
              </div>
              <StatusBadge type="done">Færdig</StatusBadge>
            </button>,
            <button
              // key={2}
              className="list-group-item list-group-item-action d-flex w-100 justify-content-start gap-3 ps-4 pe-5 py-2"
              disabled={buttonState.state !== "IDLE"}
              onClick={() => {
                if (currentOpgave)
                  putOpgaveBrugerStatus(
                    currentOpgave.id,
                    (currentOpgave.brugerStatus =
                      OpgaveBrugerStatus.ikkeTildelt)
                  );
              }}
            >
              <div style={{ minWidth: "20px" }}>
                {currentOpgave?.brugerStatus ===
                  OpgaveBrugerStatus.ikkeTildelt && (
                  <CheckLg width={20} />
                )}
              </div>
              <div style={{ minWidth: "30px" }}>
                <div
                  className="c-avatar c-avatar--sm bg-primary text-white"
                  style={{
                    visibility:
                      currentOpgave?.tilhoererBruger?.initialer
                        .length &&
                      currentOpgave.brugerStatus ===
                        OpgaveBrugerStatus.ikkeTildelt
                        ? "hidden"
                        : "hidden",
                  }}
                >
                  {currentOpgave?.tilhoererBruger?.initialer}
                </div>
              </div>
              <StatusBadge type="open">Åben</StatusBadge>
            </button>,
            <button
              style={
                hasKanIkkeLoesesButton
                  ? { display: "flex" }
                  : { display: "none" }
              }
              // key={3}
              className="list-group-item list-group-item-action w-100 justify-content-start gap-3 ps-4 pe-5 py-2"
              onClick={() => {
                if (currentOpgave)
                  putOpgaveBrugerStatus(
                    currentOpgave.id,
                    (currentOpgave.brugerStatus =
                      OpgaveBrugerStatus.kanIkkeLoeses)
                  );
              }}
            >
              <div style={{ minWidth: "20px" }}>
                {currentOpgave?.brugerStatus ===
                  OpgaveBrugerStatus.kanIkkeLoeses && (
                  <CheckLg width={20} />
                )}
              </div>
              <div style={{ minWidth: "30px" }}>
                <div
                  className="c-avatar c-avatar--sm bg-primary text-white"
                  style={{
                    visibility:
                      currentOpgave?.tilhoererBruger?.initialer
                        .length &&
                      currentOpgave.brugerStatus ===
                        OpgaveBrugerStatus.kanIkkeLoeses
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {currentOpgave?.tilhoererBruger?.initialer}
                </div>
              </div>
              <StatusBadge type="pause">Fejl</StatusBadge>
            </button>,
          ]}
        /> */}
        </>
      )}
    </>
  );
}

interface OpgaveStatusToggleProps {
  opgaver: Opgave[] | undefined;
  setOpgaver: (opgaver: Opgave[]) => void;
  currentOpgave: Opgave | undefined;
  brugerStatusUI?: OpgaveBrugerStatusUI[];
  erSkatteforvaltningen: boolean;
}

export interface ButtonState {
  state: "IDLE" | "LOADING" | "ERROR";
}
