import { useHistory } from "react-router-dom";
import StatusBadge from "routes/Fejllister/StatusBadge";
import FolderIcon from "components/common/icons/Folder";
import DontShow from "components/common/icons/DontShow";
import CheckLg from "components/common/icons/CheckLg";
import StickerIcon from "components/common/icons/StickerIcon";
import useMediaQuery from "../../../hooks/useMediaQuery";
import StatusDialog from "components/common/StatusDialog/StatusDialog";

export default function OpgaveDropdown(props: StatusDropdownProps) {
  const { opgavelister, opgaver, opgaverLoading, currentOpgave } = props;

  const history = useHistory();
  const matches = useMediaQuery("(max-width: 1050px)");
  const matches500 = useMediaQuery("(max-width: 500px)");

  return (
    <>
      {opgaver && opgaver.length > 0 && (
        <>
          <StatusDialog
            width={matches ? "auto" : "300px"}
            title="Aktuel visning"
            arrowClass="text-muted"
            noArrow={matches ? true : false}
            preventCloseOnSelect={false}
            scroll={opgaver?.length > 10 ? true : false}
            dropdownTriggerStyle={`w-100 has-tooltip tooltip-start tooltip-bottom
              ${currentOpgave ? "bg-warning-subtle" : "bg-body-tertiary"}`}
            menuClassNames="start-0"
            activeButtonStyle={matches ? true : false}
            element={
              <>
                {!matches && (
                  <>
                    {currentOpgave ? (
                      <h4 className="fs-5 fw-semibold flex-grow-1 d-flex align-items-center justify-content-between text-nowrap">
                        Fejl til gennemsyn {opgaver.indexOf(currentOpgave) + 1}
                        <span className="text-muted">
                          {opgaver.indexOf(currentOpgave) + 1} /{" "}
                          {opgaver?.length}
                        </span>
                      </h4>
                    ) : opgaverLoading ? (
                      <></>
                    ) : (
                      <h4 className="fs-5 fw-semibold text-muted opacity-75">
                        Vælg fejl til gennemsyn
                      </h4>
                    )}
                  </>
                )}
                {matches && <StickerIcon width={18} />}
              </>
            }
            menu={
              <>
                {opgaver.map((opgave, index) => (
                  <button
                    className="list-group-item list-group-item-action d-flex align-items-start gap-3 py-3"
                    style={matches500 ? { width: "100%" } : { width: "450px" }}
                    key={index}
                    onClick={() => {
                      const params = new URLSearchParams(
                        history.location.search
                      );
                      params.set("opgaveid", opgave.id.toString());
                      history.push(history.location.pathname + "?" + params);
                    }}
                  >
                    <div
                      style={{ width: "22px", height: "22px" }}
                      className="mt-1 text-primary"
                    >
                      {currentOpgave === opgave && <CheckLg width={22} />}
                    </div>
                    <div className="w-100 d-flex flex-column align-items-start gap-1">
                      <div className="w-100 d-flex justify-content-between  align-items-center">
                        <h3 className="h4 fw-medium text-primary mb-0">
                          Fejl til gennemsyn {index + 1}
                        </h3>
                        <div className="d-flex align-items-center gap-2">
                          <div
                            className="c-avatar c-avatar--sm bg-primary text-white"
                            style={{
                              visibility: opgave.tilhoererBruger?.initialer
                                .length
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {opgave.tilhoererBruger?.initialer}
                          </div>
                          {opgave.brugerStatus === 0 && (
                            <StatusBadge type="open">Åben</StatusBadge>
                          )}
                          {opgave.brugerStatus === 2 && (
                            <StatusBadge type="done">Færdig</StatusBadge>
                          )}
                          {opgave.brugerStatus === 1 && (
                            <StatusBadge type="onit">I gang</StatusBadge>
                          )}
                          {opgave.brugerStatus === 5 && (
                            <StatusBadge type="pause">Fejl</StatusBadge>
                          )}
                        </div>
                      </div>
                      <div className="text-muted d-flex align-items-center gap-2">
                        <FolderIcon width={20} height={20} />
                        <p
                          className="small mb-0 text-start text-truncate"
                          style={{
                            width: "280px",
                          }}
                        >
                          {opgavelister?.find(
                            (liste) => liste.id === opgave.listeId
                          )?.navn ?? ""}
                        </p>
                      </div>
                    </div>
                  </button>
                ))}

                <button
                  className="list-group-item list-group-item-action d-flex align-items-start gap-3 py-3"
                  style={{ width: "450px" }}
                  onClick={() => {
                    const params = new URLSearchParams(history.location.search);
                    params.set("opgaveid", "");
                    history.push(history.location.pathname + "?" + params);
                  }}
                >
                  <div className="mb-0 text-muted d-flex align-items-center gap-3">
                    <DontShow
                      width={18}
                      style={{ minWidth: "23px", height: "22px" }}
                    />
                    <p className="mb-0">Vis ikke fejl</p>
                  </div>
                </button>
              </>
            }
          />
        </>
      )}
    </>
  );
}

interface StatusDropdownProps {
  opgaver: Opgave[] | undefined;
  opgaverLoading: boolean;
  currentOpgave: Opgave | undefined;
  opgavelister: OpgaveListe[] | undefined;
}

export interface ButtonState {
  state: "IDLE" | "LOADING" | "ERROR";
}
