import { get, post, put, getResponse, del } from "../index";
import { OpgaveListePageOptions } from "interfaces/paging";
import { AxiosResponse } from "axios";

const getAll = async (options?: OpgaveListePageOptions) => {
  return await (
    await getAllWithHeaders(options)
  ).data;
};
const getAllWithHeaders = async (options?: OpgaveListePageOptions) => {
  try {
    if (options) {
      const { page = 1, pageSize = 50, listeStatus, listeSortOrder } = options;
      return await getResponse<OpgaveListe[]>({
        query: `liste?page=${page}&pageSize=${pageSize}${
          listeStatus ? `&listeStatus=${listeStatus.toString()}` : ""
        }${listeSortOrder ? `&sortorder=${listeSortOrder}` : ""}`,
      });
    } else {
      return await getResponse<OpgaveListe[]>({
        query: `liste?page=1&pageSize=100000`,
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
const getListeFromId = async (
  id: OpgaveListe["id"],
  kommunenr?: string,
  brugerId?: string
) => {
  try {
    if (!id) throw new Error("No id provided");
    let query;
    if (brugerId || kommunenr) {
      query = `liste/${id}?filterEgne=${!!brugerId}&${
        kommunenr ? `filterKommuner=${kommunenr}` : ""
      }`;
    } else {
      query = `liste/${id}`;
    }
    return await get<OpgaveListe>({
      query,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const createListe = async (liste: StrippedOpgaveListe) => {
  try {
    return await post<OpgaveListe>({
      query: `liste`,
      body: liste,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

// Omit antalOpgaver, as it should not be edited (It probably can't)
const updateListe = async (liste: StrippedOpgaveListe, listeId: string) => {
  try {
    return await put<OpgaveListe>({
      query: `liste/${listeId}`,
      body: liste,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListeAdgange = async (listeid: string) => {
  try {
    return await get<Organisation[]>({
      query: `liste/${listeid}/organisation/`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
const addListeAdgang = async (listeid: string, orgid: string) => {
  try {
    return await post<Organisation[]>({
      query: `liste/${listeid}/organisation/${orgid}`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
const removeListeAdgang = async (listeid: string, orgid: string) => {
  try {
    return await del<Organisation[]>({
      query: `liste/${listeid}/organisation/${orgid}`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const addBrugerStatus = async (listeid: string, statusId: number) => {
  try {
    return await post<BrugerStatus[]>({
      query: `liste/${listeid}/brugerstatus/${statusId}`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
const removeBrugerStatus = async (listeid: string, statusId: number) => {
  try {
    return await del<BrugerStatus[]>({
      query: `liste/${listeid}/brugerstatus/${statusId}`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const api: OpgavelisteApi = {
  getAll,
  getAllWithHeaders,
  getListeFromId,
  createListe,
  updateListe,
  getListeAdgange,
  addListeAdgang,
  removeListeAdgang,
  addBrugerStatus,
  removeBrugerStatus,
};

export default api;

export interface OpgavelisteApi {
  getAll: {
    (options?: OpgaveListePageOptions): Promise<Array<OpgaveListe>>;
  };
  getAllWithHeaders: {
    (options?: OpgaveListePageOptions): Promise<AxiosResponse<OpgaveListe[]>>;
  };
  getListeFromId: {
    (
      id: OpgaveListe["id"],
      kommunenr?: string,
      brugerId?: string
    ): Promise<OpgaveListe>;
  };
  createListe: {
    (liste: StrippedOpgaveListe): Promise<any>;
  };
  updateListe: {
    (liste: StrippedOpgaveListe, listeId: string): Promise<any>;
  };
  getListeAdgange: {
    (listeid: string): Promise<Organisation[]>;
  };
  addListeAdgang: {
    (listeid: string, orgid: string): Promise<Organisation[]>;
  };
  removeListeAdgang: {
    (listeid: string, orgid: string): Promise<AxiosResponse<Organisation[]>>;
  };
  addBrugerStatus: {
    (listeId: string, statusId: number): Promise<BrugerStatus[]>;
  };
  removeBrugerStatus: {
    (listeId: string, statusId: number): Promise<AxiosResponse<BrugerStatus[]>>;
  };
}
